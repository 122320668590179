// css for print
import "@/assets/scss/print.css";
import "@/assets/scss/printCustom.scss";

// css global
import "@/assets/scss/main.css";
import "@/assets/scss/custom.scss";
import "@/assets/scss/phuong.scss";
import "@/assets/scss/sidebar.scss";
import "@/assets/scss/weekPicker.scss";
import "@assets/scss/modal.css"
import "@/assets/scss/setting.scss";

import type { AppProps } from "next/app";
import { store } from "@/store";
import { Provider } from "react-redux";
import { Loading } from "@/components/Loading";
import Msg from "@/components/Msg";
import AssetsProvider, { AssetsContext } from "../context/theme";
import React from "react";
import { HookContext, useHook } from "@/context/hook";
import { AllModals } from "@/components/modals";
import Head from "next/head";
import { imgAssets } from "@/constants/img";
import ErrorBoundary from "@/errors/global";
import { PauseTime } from "@/components/tasks/blocks/pauseTime";

function App({ Component, pageProps }: AppProps) {
  //add store to Provider
  const assetContext = React.useContext(AssetsContext) as any;
  const hookContext = useHook() as any;
  const merProps = { ...pageProps, ...assetContext };
  return (
    <ErrorBoundary {...merProps}>
      <main className="main__body" id="main">
        <Provider store={store}>
          <Head>
            {/* <title>AAA 456</title> */}
            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="initial-scale=1.0, width=device-width"
            />
            <link rel="icon" href={imgAssets.logo2.src} sizes="32x32" />
          </Head>
          <AssetsProvider>
            <HookContext.Provider value={hookContext}>
              <Msg />

              <Component {...merProps} />

              <Loading />
              <AllModals {...merProps} />
              <PauseTime></PauseTime>
            </HookContext.Provider>
          </AssetsProvider>
        </Provider>
      </main>
    </ErrorBoundary>
  );
}

export default App;
