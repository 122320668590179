import { HookContext, TContextHook } from "@/context/hook";
import React, { useEffect } from "react";
import { Navigator } from "@/components/tasks/blocks/navigator";
import { Statistics } from "@/components/tasks/blocks/statistics";
import { TaskPark } from "@/components/tasks/blocks/taskPart";
import { taskSliceAction } from "@/store/task";
import { container } from "@/di";
import moment from "moment";
import { TaskViewModel } from "@/modelviews/task";
import { ITask, statisticsType, TaskModal } from "@/models/Task";
import { DoneTask } from "@/components/tasks/modals/doneTask";
import { ProcessBar } from "../blocks/processBar";
import { SideBar } from "../blocks/sideBar";


interface Props {

}

export const HomeComponent: React.FC<Props> = ({ children }: any ) => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const stateTask = hooks.useSelector((state: any) => state.task);
  const taskVM = container.resolve<TaskViewModel>(TaskViewModel);
  taskVM.setHooks(hooks);
  

  
  const pauseTime = async (stopType: string) => {
    await taskVM.pauseTime(stopType);
    await handlePauseTime();
  }
  
  const handlePauseTime = async () => {
    let type = stateTask.statistics.type == statisticsType.date ? statisticsType.month : statisticsType.date;
    if(!stateTask.statistics.data) {
      type = statisticsType.date
    }
    
    const res = await taskVM.getStatistic({
      from: type == statisticsType.month ? moment().startOf("month") : stateTask.statistics.date,
      to: type == statisticsType.month ? moment().endOf("month") : stateTask.statistics.date
    });
    hooks.dispatch(
      taskSliceAction.updateStatistics([
        {
          key: "data",
          val: {
            ...res.data,
            pause_time: res.data.pause_time && {
              ...res.data.pause_time,
              start_time_local: moment()
            }
          }
        }
      ])
    )
    
    const resTask = await taskVM.getTask({page: stateTask.task.currentPage, limit: stateTask.task.limit, hide_input_process: 1, without_old_completed: 1});
    
    hooks.dispatch(
      taskSliceAction.updateTask([
        {
          key: "data",
          val: resTask.data?.data.map((item: any) => {
            return {
              ...item,
              clientTime: moment()
            }
          })
        },
        {
          key: "lastPage",
          val: resTask.data?.last_page
        },
        {
          key: "currentPage",
          val: stateTask.task.currentPage
        }
      ])
    )
  }
  
  const closeDoneTaskModal = () => {
    hooks.dispatch(
      taskSliceAction.updateStore([
        {
          key: "currentTask",
          val: null
        },
        {
          key: "modal",
          val: null
        }
      ])
    )
  }
  
  const doneTask = async (taskId: number) => {
    await taskVM.doneTask(taskId, {all_flg: true});
    
    let type = stateTask.statistics.type == statisticsType.date ? statisticsType.month : statisticsType.date;
    if(!stateTask.statistics.data) {
      type = statisticsType.date
    }
  
    
    const resTask = await taskVM.getTask({page: stateTask.task.currentPage, limit: stateTask.task.limit, hide_input_process: 1, without_old_completed: 1});
    
    hooks.dispatch(
      taskSliceAction.updateTask([
        {
          key: "data",
          val: resTask.data?.data.map((item: any) => {
            return {
              ...item,
              clientTime: moment()
            }
          })
        },
        {
          key: "lastPage",
          val: resTask.data?.last_page
        },
        {
          key: "currentPage",
          val: stateTask.task.currentPage
        }
      ])
    )
    
    closeDoneTaskModal();
  }
  
  
  useEffect(() => {
    closeDoneTaskModal();

    return ()=>{
      hooks.dispatch(taskSliceAction.resetTasks())
    }

  }, []);
  
  return (
    <>
      <section className="main-top modal-open">
        { children }
        <section className="layoutTop">
          <div className="dflex blockInfo">
            <div className="leftside">
              <Navigator pauseTime={(type: string) => { pauseTime(type) }}></Navigator>
              <Statistics></Statistics>
            </div>
            <TaskPark></TaskPark>
          </div>
          <ProcessBar></ProcessBar>
        </section>
        {
          stateTask.popUp.modal == TaskModal.doneTask &&
          <DoneTask 
            task={stateTask.popUp.currentTask}
            closeModal={() => closeDoneTaskModal()}
            doneTask={() => doneTask(stateTask.popUp.currentTask?.id)}
          ></DoneTask>
        }
        {
          stateTask.showSideBar && <SideBar></SideBar>
        }
      </section>
    </>
  );
};
